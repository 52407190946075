import {
  __ONLY_WRITE_STATE_FROM_ACTIONS as writeState,
  isPersistent,
  readState,
} from "@/__main__/app-state.mjs";
// import { IS_TESTING } from "@/__main__/constants.mjs";
// import featureFlags, { allowRead } from "@/__main__/feature-flags.mjs";
import getData, { postData } from "@/__main__/get-data.mjs";
import { EVENTS, handleMessage, initEvents } from "@/__main__/ipc-core.mjs";
import router, {
  // fullPageLoad,
  setRoute,
  updateRoute,
} from "@/__main__/router.mjs";
import { resetLiveGame, setLiveGame, writeSettings } from "@/app/actions.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { appURLs, GAME_SHORT_NAMES } from "@/app/constants.mjs";
import noopModel from "@/data-models/no-op.mjs";
import ValorantProfileModel from "@/data-models/valorant-profile.mjs";
import {
  updateLoggedInAccounts,
  updateMatch,
  updatePrivacy,
  updateStateProfile,
  writeCoachingExtraStats,
  writeLiveMatch,
} from "@/game-val/actions.mjs";
import { getAgentsIdKeyMap } from "@/game-val/agents-utils.mjs";
import * as API from "@/game-val/api.mjs";
import { VALORANT_LINEUPS_PATH } from "@/game-val/constants.mjs";
import { GAME_SYMBOL_VAL } from "@/game-val/definition-symbol.mjs";
import handleValOverlayMessages from "@/game-val/handle-overlay-messages.mjs";
import { valorantRefs } from "@/game-val/refs.mjs";
import { VAL_LIVE_GAME_PATH } from "@/game-val/routes.mjs";
import { getProfileIcon } from "@/game-val/static.mjs";
import {
  formatProfile,
  getNameTag,
  nameTagNullCheck,
} from "@/game-val/utils.mjs";
import {
  EVENT_VAL_ENTER_GAME,
  EVENT_VAL_EXIT_GAME,
} from "@/game-val/val-client-api.mjs";
import { OVERLAYS } from "@/library/actions.mjs";
import { EVENT_VAL_MATCH_STATS } from "@/routes/constants.mjs";
import clone from "@/util/clone.mjs";
import deepMerge from "@/util/deep-merge.mjs";
import { appLog, devDebug, devError } from "@/util/dev.mjs";
import diff from "@/util/diff.mjs";
import globals from "@/util/global-whitelist.mjs";
import isRouteOverlay from "@/util/is-route-overlay.mjs";

const DISABLE_ROUNDS_MUTATION = true;

const redirectToLineups = (map) => {
  const {
    val: {
      meta: {
        maps: { list },
      },
    },
  } = readState;
  const metaMap = list.find(({ key }) => key === map);
  if (metaMap) {
    setRoute(`${VALORANT_LINEUPS_PATH}?map=${map}`);
  }
};

/**
 * Global Val event handlers only for the app.
 */
async function initVal() {
  await initEvents;

  if (isRouteOverlay(globals.location?.pathname)) {
    handleValOverlayMessages();
  }

  const matchDataExtra: Record<string, string> = {
    val_coaching_version: "1",
  };

  handleMessage(EVENTS.VALORANT_PLAYER, async ({ player, redirect }) => {
    const nameTag = getNameTag(player.name, player.tag);
    if (nameTagNullCheck(nameTag)) {
      const updatedProfile = formatProfile(player);
      matchDataExtra.player_id = updatedProfile.internalUuid;
      updateLoggedInAccounts(updatedProfile);
      updateStateProfile(updatedProfile);

      appLog("[VALORANT] Attempting to update or create profile", {
        nameTag,
        internalUuid: updatedProfile.internalUuid,
        region: updatedProfile.region,
      });
      try {
        await postData(
          API.updateProfile(updatedProfile),
          noopModel,
          ["val", "updateProfile"],
          { skipLoadingPlaceholder: true },
        );
        appLog("[VALORANT] Profile updated successfully");
      } catch (error) {
        if (
          typeof error === "object" &&
          "errorCode" in error &&
          error["errorCode"] === "not_found"
        ) {
          appLog("[VALORANT] Profile not found, creating new profile");
          await postData(
            API.createProfile(updatedProfile),
            noopModel,
            ["val", "createProfile"],
            { skipLoadingPlaceholder: true },
          );
        } else {
          appLog("[VALORANT] Unhandled error updating profile", error);
        }
      }

      if (redirect && !router.route?.searchParams?.get("postmatch")) {
        setRoute(`/valorant/profile/${nameTag}`);
      }
    }
  });

  handleMessage(
    EVENTS.VALORANT_PLAYER_PRIVACY,
    async ({ name, tag, isPrivate }) => {
      const bearerToken = await valorantRefs.getBearerToken();
      if (!bearerToken) return;
      const headers = {
        Authorization: bearerToken,
      };
      await postData(
        API.updateProfilePrivacy({
          gameName: name,
          tagLine: tag,
          private: isPrivate,
        }),
        noopModel,
        ["val", "updateProfilePrivacy"],
        { skipLoadingPlaceholder: true, headers },
      ).then(() => updatePrivacy(name, tag, isPrivate));
    },
  );

  handleMessage(
    EVENTS.VALORANT_MATCH_MMR,
    async ({ match, player, matchMMR }) => {
      if (!match || !player) {
        devDebug("Weird. MatchMMR event received without any data");
        return;
      }

      const bearerToken = await valorantRefs.getBearerToken();
      const headers = {
        Authorization: bearerToken,
      };

      const nameTag = getNameTag(player.name, player.tag);
      const profile = await getData(
        API.getProfileByInternalUuid({ internalUuid: player.id }),
        ValorantProfileModel,
        ["val", "profiles", nameTag],
        { skipSafetyCheck: true, headers },
      );

      await postData(
        API.createMatchStats({
          puuid: profile.puuid,
          region: player.region,
          userAccountId: readState.user?.id,
          gameId: match.id,
          actUuid: match.season,
          ratingAfter: matchMMR?.ratingAfter,
          ratingBefore: matchMMR?.ratingBefore,
          tierAfter: matchMMR?.tierAfter,
          tierBefore: matchMMR?.tierBefore,
        }),
        noopModel,
        ["val", "createMatchStats"],
        { skipLoadingPlaceholder: true },
      );
    },
  );

  handleMessage(
    EVENTS.VALORANT_VIEW_BLITZ_APP,
    ({ matchId, profileId, actId }) => {
      if (matchId && actId) {
        resetLiveGame();
        setRoute(`/valorant/match/${profileId}/${actId}/${matchId}`);
      } else if (profileId) {
        resetLiveGame();
        setRoute(`/valorant/profile/${profileId.toLowerCase()}`);
      }
    },
  );

  handleMessage(EVENTS.VALORANT_AGENT_SELECTED, (agentId: string) => {
    const {
      route: { currentPath, searchParams },
    } = router;
    const {
      val: { content },
    } = readState;

    const agentsIdKeyMap = getAgentsIdKeyMap(content?.agents);
    const agent = agentsIdKeyMap[agentId.toUpperCase()];

    if (agent) {
      (searchParams as URLSearchParams).set("agent", agent);
      matchDataExtra["agent"] = agent;
      updateRoute(currentPath, searchParams);
    }
  });

  handleMessage(EVENTS.VALORANT_PREGAME_DETAILS, ({ map }) => {
    redirectToLineups(map);
  });

  type MatchLoadingStart = {
    matchId: string;
    agentId: string;
    region: string;
    queue: string;
    mode: string;
    map: string;
    profileId: string;
    subject: string;
  };

  handleMessage(
    EVENTS.VALORANT_MATCH_LOADING_START,
    async (matchLoadingStart: MatchLoadingStart) => {
      const profile = await getData(
        API.getProfileByInternalUuid({
          internalUuid: matchLoadingStart.subject,
        }),
        ValorantProfileModel,
        ["val", "profiles", matchLoadingStart.profileId?.toLowerCase()],
        {
          skipSafetyCheck: true,
          shouldFetchIfPathExists: false,
          headers: {
            Authorization: await valorantRefs.getBearerToken(),
          },
        },
      );
      const puuidEncrypted = profile?.puuid;
      eventBus.emit(EVENT_VAL_ENTER_GAME, {
        ...matchLoadingStart,
        puuidEncrypted,
      });
    },
  );

  handleMessage(
    EVENTS.VALORANT_POSTMATCH_REDIRECT,
    async ({ profileId, match }) => {
      delete writeState.val.live[profileId];
      try {
        await updateMatch(match, profileId);
      } catch (e) {
        devError("FAILED TO UPDATE VALORANT MATCH", e);
      }
      if (nameTagNullCheck(profileId) && match) {
        try {
          await eventBus.emit(EVENT_VAL_EXIT_GAME, {
            puuid: profileId,
            gameId: match.id,
            season: match.season,
          });
        } catch (e) {
          devError("FAILED TO HANDLE VALORANT POSTMATCH", e);
        }

        const path = `/valorant/match/${profileId}/${match.season}/${match.id}`;
        const searchParams = {
          queue: match.queue,
          postmatch: "1",
        };

        resetLiveGame();

        // allowRead();
        // if (featureFlags.ads && !IS_TESTING) {
        // Full page load to reset memory from video ads 😬
        // fullPageLoad(path, searchParams);
        // } else {
        await setRoute(path, searchParams);
        // }
      }
    },
  );

  /**
   * Register a handleMessage listener for when overlay settings
   * are changed in-game.
   */
  handleMessage(EVENTS.VALORANT_OVERLAY_SETTINGS, (overlaySettings: object) => {
    const prevOverlaySettings = clone(
      readState.settings[GAME_SHORT_NAMES[GAME_SYMBOL_VAL]][OVERLAYS],
    );

    const changes = diff(prevOverlaySettings, overlaySettings);

    const nextOverlaySettings = deepMerge(prevOverlaySettings, changes, false);

    writeSettings(
      [GAME_SHORT_NAMES[GAME_SYMBOL_VAL], OVERLAYS],
      nextOverlaySettings,
    );
  });

  handleMessage(
    EVENTS.VALORANT_ROUND_DATA,
    ({ gameName, tagLine, roundData: liveData }) => {
      liveData[isPersistent] = Date.now() + 1000 * 60 * 60;
      const profileId = getNameTag(gameName, tagLine);
      const {
        val: { live, profiles, content },
      } = readState;

      const lowercaseName = profileId?.toLowerCase?.();
      const profile = profiles[lowercaseName];
      const playerCardId = profile?.valorantProfile?.playerCardId;
      const playerProfileImage = playerCardId
        ? getProfileIcon(playerCardId)
        : `${appURLs.CDN}/blitz/val/assets/generic-profile-icon.png`;

      const currentLiveGameId = live[profileId]?.gameId;

      if (currentLiveGameId !== liveData.gameId) {
        const inGameLink = `/valorant/in-game/${profileId}`;
        setLiveGame(
          GAME_SYMBOL_VAL,
          {
            show: true,
            link: inGameLink,
            image: playerProfileImage,
            game: GAME_SYMBOL_VAL,
          },
          Date.now(),
        );
        setRoute(inGameLink);
      }

      writeLiveMatch(profileId, liveData);

      const currentRound = liveData.rounds[liveData.rounds.length - 1];

      const gameMapUuid = content.gameMaps.find(
        (m) => m.path === liveData.gameMapPath,
      )?.uuid;
      if (!DISABLE_ROUNDS_MUTATION && currentRound) {
        const rounds = liveData.players.map((p) => {
          return {
            gameName: p.gameName,
            tagLine: p.tagLine,
            internalUuid: p.internalUuid,
            teamId: p.teamId,
            agentUuid: p.agent.uuid.toUpperCase(),
            gameId: liveData.gameId,
            gameMapUuid,
            mode: liveData.mode,
            gameStart: new Date(liveData.gameStart),
            number: currentRound.roundNumber,
            headshotKills:
              currentRound.playerStats[p.internalUuid]?.headshotKills || 0,
            kills: currentRound.playerStats[p.internalUuid]?.kills || 0,
            assists: currentRound.playerStats[p.internalUuid]?.assists || 0,
            score: currentRound.playerStats[p.internalUuid]?.score || 0,
            abilityKills:
              currentRound.playerStats[p.internalUuid]?.abilityKills || 0,
            region: liveData.region,
            queue: liveData.queue,
          };
        });
        postData(API.createRounds({ rounds }), noopModel, undefined, {
          skipLoadingPlaceholder: true,
        }).catch((error) => {
          devError("FAILED TO SEND ROUND DATA", error);
        });
      }
    },
  );

  handleMessage(EVENTS.VALORANT_QUIT, ({ gameName, tagLine }) => {
    const profileId = getNameTag(gameName, tagLine);
    delete writeState.val.live[profileId];
    if (VAL_LIVE_GAME_PATH.test(router.route?.currentPath)) {
      setRoute(`/${GAME_SHORT_NAMES[GAME_SYMBOL_VAL]}/profile/${profileId}`);
    }
  });

  // add extra data to reversed data
  handleMessage(
    EVENTS.VALORANT_MATCH_STATS,
    (payload: Record<string, unknown>) => {
      payload["extra"] = matchDataExtra;
      writeCoachingExtraStats(payload);
      eventBus.emit(EVENT_VAL_MATCH_STATS, payload);
    },
  );
}

export default initVal;
